import React, {Fragment} from "react";
import {Card, Form} from "tabler-react";
import {Image, Button, ButtonToolbar, Figure, Modal} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import {useEffect, useState} from "react";
import {publicationSchema} from '@unty-gl/validation';
import PublicationsService from "../../../services/domain/PublicationsService";
import {useToasts} from "react-toast-notifications";
import {Formik} from "formik";
import {Col, Container, FormGroup, Input, Label, Row} from 'reactstrap';
import DropZone from "../../common/dropzone/DropZone";
import LinksProvider from "../../../services/http/LinksProvider";
import {PublicationCategory, PublicationStatus, PublicationTemplate} from "../../../models/Publication";
import ErrorHandler from "../../../services/error/ErrorHandler";
import DraftEditor from "../../common/editor/DraftEditor";

const PublicationCreate = ({t}) => {

  const {addToast} = useToasts();

  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState([null, null, null, null, null, null, null]);
  const [currentCategory, setCurrentCategory] = useState(undefined);

  const componentConfig = {
    iconFiletypes: [".jpg", ".png", ".jpeg"],
    showFiletypeIcon: true,
    postUrl: (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:1337') + LinksProvider.API.PUBLICATIONS.UPLOAD_PHOTO
  };

  const dzConfig = {
    eventHandlers: (setUrl, removeUrl) => {
      return {
        success: (file, response) => {
          if (response && response.data && response.data.image) {
            setUrl(response.data.image.url);
          }
        },
        removedfile: (file) => {
          removeUrl();
        }
      }
    },
    djsConfig: {
      maxFilesize: 10,
      maxFiles: 1,
      dictDefaultMessage: t('publications.upload-photo'),
      dictRemoveFile: t('publications.remove-photo'),
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/jpg,image/png",
      maxfilesexceeded: function (file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      sending: function (file, xhr, formData) {
        formData.append("image", file);
      },
      canceled: function (file) {
        console.log("delete photo")
      }
    }
  };

  const getPhotosError = (error, template) => {
    if (error === "MIN_LENGTH") {
      return t('publications.validation.MIN_LENGTH_PHOTOS');
    } else if (error === "photos is invalid") {
      return t('publications.validation.INVALID_PHOTOS-' + template);
    }
  }

  const onSubmit = (values) => {
    console.log("submitted");
    !values.second_text && (values.second_text = undefined);
    !values.third_text && (values.third_text = undefined);
    setLoading(true);
    PublicationsService.create(values)
      .then(publication => {
        addToast(t(`publications.added`), {
          appearance: 'success', autoDismiss: true
        });
        window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.PUBLICATIONS);
      })
      .catch(error => {
        ErrorHandler.handleError(addToast, t, error)
      })
      .finally(() => setLoading(false));
  };


  return (<Fragment>
    <Formik
      validationSchema={publicationSchema.create}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        meta_title: '',
        meta_description: '',
        title: '',
        template: 0,
        category: 0,
        status: 0,
        first_text: '',
        second_text: '',
        third_text: '',
        photos: photos,
        photoUrls: ['', '', '', '', '', '', ''],
        is_headline: undefined
      }}
    >
      {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card.Header>
              <Modal.Title> {t('publications.create')}</Modal.Title>
            </Card.Header>
            <Card.Body>
              <Container>
                <Row>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label>{t('publications.meta_title')}</Label>
                      <Input
                        id="meta_title"
                        placeholder={t('publications.meta_title')}
                        type="text"
                        value={values['meta_title'] || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched['meta_title'] && errors['meta_title'] ?
                        <p className="text-danger">{t('publications.validation.' + errors['meta_title'])}</p> : null}

                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label>{t('publications.meta_description')}</Label>
                      <Input
                        id="meta_description"
                        placeholder={t('publications.meta_title')}
                        type="textarea"
                        value={values['meta_description'] || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched['meta_description'] && errors['meta_description'] ?
                        <p className="text-danger">{t('publications.validation.' + errors['meta_description'])}</p> : null}

                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label>{t('publications.title')}</Label>
                      <Input
                        id="title"
                        placeholder={t('publications.title')}
                        type="text"
                        value={values.title || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.title && errors.title ?
                        <p className="text-danger">{t('publications.validation.' + errors.title)}</p> : null}

                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" sm="6">
                    <Col xs="12" sm="12">
                      <FormGroup>
                        <Label>{t('publications.template')}</Label>
                        <Input
                          id="template"
                          placeholder={t('publications.template')}
                          type="select"
                          value={values.template || 0}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("template", parseInt(e.target.value));
                          }}
                          onBlur={handleBlur}
                        >
                          {Object.values(PublicationTemplate).map(template =>
                            (<option value={template}>
                              {t('publications.template-' + template)}
                            </option>)
                          )}
                        </Input>
                        {touched.template && errors.template ?
                          <p className="text-danger">{t('publications.validation.' + errors.template)}</p> : null}

                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="12">
                      <FormGroup>
                        <Label>{t('publications.status')}</Label>
                        <Input
                          id="status"
                          placeholder={t('publications.status')}
                          type="select"
                          value={values.status || 0}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("status", parseInt(e.target.value))
                          }}
                          onBlur={handleBlur}
                        >
                          {Object.values(PublicationStatus).map(status =>
                            (<option value={status}>
                              {t('publications.status-' + status)}
                            </option>)
                          )}
                        </Input>
                        {touched.status && errors.status ?
                          <p className="text-danger">{t('publications.validation.' + errors.status)}</p> : null}

                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="12">
                      <FormGroup>
                        <Label>{t('publications.category')}</Label>
                        <Input
                          id="category"
                          placeholder={t('publications.category')}
                          type="select"
                          value={values.category || 0}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("category", parseInt(e.target.value));
                            setCurrentCategory(parseInt(e.target.value));
                          }}
                          onBlur={handleBlur}
                        >
                          {Object.values(PublicationCategory).map(category =>
                            (<option value={category}>
                              {t('publications.category-' + category)}
                            </option>)
                          )}
                        </Input>
                        {touched.category && errors.category ?
                          <p className="text-danger">{t('publications.validation.' + errors.category)}</p> : null}

                      </FormGroup>
                    </Col>
                    {currentCategory !== undefined && currentCategory !== null  && currentCategory === PublicationCategory.HONEYMOON && (
                      <Col xs="12" sm="12">
                        <FormGroup>
                          <Form.Checkbox
                            isInline
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={t('publications.is_headline')}
                            name="is_headline"
                            value={values.is_headline}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Col>
                  <Col xs="6" sm="6">
                    <div className="d-flex justify-content-center align-content-center">
                      <img src={`/assets/images/publication-template-${values.template}.png`} alt={'template'}/>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label for="first-text">{t('publications.first-text')}</Label>
                      <DraftEditor value={values['first_text']}
                                   editorName="first_text"
                                   editorId="first_text"
                      />
                    </FormGroup>
                    {touched.first_text && errors.first_text ?
                      <p className="text-danger">{t('publications.validation.' + errors.first_text)}</p> : null}
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label for="second_text">{t('publications.second-text')}</Label>
                      <DraftEditor value={values['second_text']}
                                   editorName="second_text"
                                   editorId="second_text"
                      />
                    </FormGroup>
                    {touched.second_text && errors.second_text ?
                      <p className="text-danger">{t('publications.validation.' + errors.second_text)}</p> : null}
                  </Col>
                </Row>
                {values.template === PublicationTemplate.SECONDARY && (<Row>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label for="third_text">{t('publications.third-text')}</Label>
                      <DraftEditor value={values['third_text']}
                                   editorName="third_text"
                                   editorId="third_text"
                      />
                    </FormGroup>
                    {touched.third_text && errors.third_text ?
                      <p className="text-danger">{t('publications.validation.' + errors.third_text)}</p> : null}
                  </Col>
                </Row>)}
                {values.template === PublicationTemplate.SECONDARY && (<Row>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label for="fourth_text">{t('publications.fourth-text')}</Label>
                      <DraftEditor value={values['fourth_text']}
                                   editorName="fourth_text"
                                   editorId="fourth_text"
                      />
                    </FormGroup>
                    {touched.fourth_text && errors.fourth_text ?
                      <p className="text-danger">{t('publications.validation.' + errors.fourth_text)}</p> : null}
                  </Col>
                </Row>)}
                <Row>
                  {[...Array(7).keys()].map((element, index) => {
                    return (
                      <Col xs="6" sm="6" className="mt-2">
                        <Row>
                          <Col xs="12" sm="12">
                            <DropZone
                              componentConfig={componentConfig}
                              dropZoneConfig={dzConfig.djsConfig}
                              eventHandlers={dzConfig.eventHandlers(
                                url => {
                                  photos[index] = url;
                                  setPhotos(photos);
                                  setFieldValue("photos", photos);
                                },
                                () => {
                                  photos[index] = null;
                                  setPhotos(photos);
                                  setFieldValue("photos", photos);
                                }
                              )}
                            />
                          </Col>
                          <Col xs="12" sm="12">
                            <Input
                              id={"photo_url_" + index}
                              placeholder={t('publications.photo-url')}
                              type="text"
                              value={values.photoUrls[index] || ''}
                              onChange={(e) => {
                                handleChange(e);
                                values.photoUrls[index] = e.target.value;
                                setFieldValue("photoUrls", values.photoUrls);
                              }}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Row>
                        <p className="w-100 mt-3 mb-1 text-center">{t('publications.photo-' + index)}</p>
                      </Col>
                    )
                  })}
                </Row>
                {errors.photos ? <p
                  className="text-danger mt-2">{getPhotosError(errors.photos, values.template)}</p> : null}
              </Container>
            </Card.Body>
            <Card.Footer>
              <Button disabled={loading} type="submit" color="primary">{t('modals.save')}</Button>
            </Card.Footer>
          </form>
        )
      }}
    </Formik>
  </Fragment>);
};

export default withI18n()(PublicationCreate);
