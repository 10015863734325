// @flow
import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {Button, Form, Grid} from "tabler-react";
import {Formik} from "formik";
import {withI18n} from "react-i18next";
import {promoCodeSchema} from '@unty-gl/validation';
import InputError from "../../common/input-error/InputError";
import PromoCodesService from "../../../services/domain/PromoCodesService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";
import DateInput from "../../common/date-input/DateInput";
import DateService from "../../../services/utils/DateService";
import AmountInput from "../../forms/amount-input/AmountInput";

const PromoCodeCreationModal = (props): React.Node => {

  const [loading, setLoading] = useState(false);
  const [markAsBanner, setMarkAsBanner] = useState(false);
  const {addToast} = useToasts();

  const onSubmit = (values, actions) => {
    setLoading(true);
    const banner_text = values.banner_text;
    delete values.banner_text;
    values.activation_date = values.activation_date ? DateService.formatDateISO(values.activation_date) : values.activation_date;
    values.expiration_date = values.expiration_date ? DateService.formatDateISO(values.expiration_date) : values.expiration_date;
    PromoCodesService.create(values)
      .then(promoCode => {
        if (markAsBanner) {
          PromoCodesService.addAsBanner(promoCode.reference, {pool_type: promoCode.pool_type, banner_text: banner_text})
            .catch(error => ErrorHandler.handleError(addToast, props.t, error))
        }
        props.onHide && props.onHide();
        props.afterCreation && props.afterCreation(promoCode);
      })
      .catch(error => ErrorHandler.handleError(addToast, props.t, error))
      .finally(() => setLoading(false));
  };

  const render = ({handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors}) => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('promo-codes.code')}>
                  <Form.Input
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="reference"
                    placeholder={props.t('promo-codes.code')}/>
                  <InputError name="reference"/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label={props.t('promo-codes.quantity')}>
                  <Form.Input
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="number"
                    name="quantity"
                    placeholder={props.t('promo-codes.quantity')}/>
                  <InputError name="quantity"/>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('promo-codes.amount')}>
                  <AmountInput
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      name="amount"
                      placeholder={props.t('promo-codes.amount')}/>
                  <InputError name="amount"/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label={props.t('promo-codes.pool_type')}>
                  <Form.SelectGroup>
                    <Form.SelectGroupItem
                      label={props.t('promo-codes.pool_type_0')}
                      name="pool_type"
                      onChange={handleChange}
                      value={0}/>
                    <Form.SelectGroupItem
                      label={props.t('promo-codes.pool_type_1')}
                      name="pool_type"
                      onChange={handleChange}
                      value={1}/>
                  </Form.SelectGroup>
                  <InputError name="pool_type"/>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('promo-codes.activation-date')}>
                  <DateInput
                    id="activation_date"
                    name="activation_date"
                    placeholder="JJ/MM/AAAA"/>
                  <InputError name="activation_date"/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label={props.t('promo-codes.expiration-date')}>
                  <DateInput
                    id="expiration_date"
                    name="expiration_date"
                    placeholder="JJ/MM/AAAA"/>
                  <InputError name="expiration_date"/>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('promo-codes.min-amount') + props.t('globals.optional')}>
                  <AmountInput
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      name="min_collected_amount"
                      placeholder={props.t('promo-codes.min-amount')}/>
                  <InputError name="min_collected_amount"/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label={props.t('promo-codes.min-participant') + props.t('globals.optional')}>
                  <Form.Input
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="number"
                    name="min_participants_number"
                    placeholder={props.t('promo-codes.min-participant')}/>
                  <InputError name="min_participants_number"/>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col width={6}>
                <Form.Group
                  label={props.t('promo-codes.duration-before-reaching-threshold') + props.t('globals.optional')}>
                  <Form.Input
                    onChange={handleChange}
                    type="number"
                    name="duration_before_reaching_threshold"
                    placeholder={props.t('promo-codes.duration-before-reaching-threshold')}/>
                  <InputError name="duration_before_reaching_threshold"/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col width={6}>
                <Form.Group label={props.t('promo-codes.mark-as-banner')}>
                  <Form.Checkbox
                    isInline
                    onChange={(e) => {
                      setMarkAsBanner(e.target.checked);
                      setFieldValue("banner_text", null)
                    }}
                    label=" "
                    name="mark-as-banner"
                    value="mark"
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            {markAsBanner ? (
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label={props.t('promo-codes.banner-text')}>
                    <Form.Input
                      onChange={handleChange}
                      type="text"
                      name="banner_text"
                      placeholder={props.t('promo-codes.banner-text')}/>
                    <InputError name="banner_text"/>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            ) : null}

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} closeButton
                    color="warning">{props.t('modals.close')}</Button>
            <Button type="submit" color="primary" disabled={loading}
                    loading={loading}>{props.t('promo-codes.create')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('promo-codes.create-promo-code')}</Modal.Title>
    </Modal.Header>
    <Formik
      onSubmit={onSubmit}
      render={render}
      validationSchema={promoCodeSchema.create}
      initialValues={{
        reference: '',
        quantity: 0,
        amount: -1,
        pool_type: -1,
        activation_date: null,
        expiration_date: null,
        min_collected_amount: null,
        min_participants_number: null,
        duration_before_reaching_threshold: null
      }}
    />
  </Modal>);
};

export default withI18n()(PromoCodeCreationModal);
