// @flow
import * as React from "react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button, Form, Grid } from "tabler-react";
import { withI18n } from "react-i18next";
import InfoWidget from "../../common/info-widget/InfoWidget";
import Amount from "../../common/amount/Amount";
import UsersService from "../../../services/domain/UsersService";
import PotsService from "../../../services/domain/PotsService";
import { Formik } from "formik";
import InputError from "../../common/input-error/InputError";
import ConfirmModal from "../confirm-modal/ConfirmModal";
import AmountInput from "../../forms/amount-input/AmountInput";
import ErrorHandler from "../../../services/error/ErrorHandler";
import { useToasts } from "react-toast-notifications";
import { PotCategory } from "../../../models/Pot";
import { cashOutSchema } from "@unty-gl/validation";

function CreateTransferModal(props): React.Node {
  const [ribs, setRibs] = useState(null);
  const [loadingRibs, setLoadingRibs] = useState(false);
  const [confirmationModalShown, setConfirmationModalShown] = useState(false);
  const { addToast } = useToasts();

  const loadRibs = () => {
    setLoadingRibs(true);
    const email = props.pot.beneficiary
      ? props.pot.beneficiary.email
      : props.pot.organizer_email;
    UsersService.getRibs(email)
      .then((ribs) => {
        const filterRibs = (ribsList) => {
          return ribsList
            ? ribsList.filter((rib) => !rib.is_deleted && !rib.is_banned)
            : null;
        };
        if (props.pot.category === PotCategory.WEDDING_LIST) {
          setRibs(filterRibs(ribs.m1l_ribs));
        } else {
          setRibs(filterRibs(ribs.cng_ribs));
        }
      })
      .finally(() => setLoadingRibs(false));
  };

  useEffect(() => {
    loadRibs();
  }, []);

  const render = ({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    touched,
    errors,
  }) => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Grid.Row>
              <Grid.Col>
                <InfoWidget
                  color="green"
                  icon="dollar-sign"
                  content={<Amount amount={props.pot.collected_amount} />}
                  text={props.t("pots.amount-collected")}
                />
              </Grid.Col>
              <Grid.Col>
                <InfoWidget
                  color="yellow"
                  icon="dollar-sign"
                  content={<Amount amount={props.pot.total_amount_left} />}
                  text={props.t("pots.amount-left")}
                />
              </Grid.Col>
              <Grid.Col>
                <InfoWidget
                  color="red"
                  icon="dollar-sign"
                  content={<Amount amount={props.pot.spent_amount} />}
                  text={props.t("pots.amount-spent")}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <InfoWidget
                  color="red"
                  icon="home"
                  content={<Amount amount={props.pot.cash_out_amount} />}
                  text={props.t("pots.amount-out-cash-out")}
                />
              </Grid.Col>
              <Grid.Col>
                <InfoWidget
                  color="orange"
                  icon="credit-card"
                  content={<Amount amount={props.pot.gift_cards_amount} />}
                  text={props.t("pots.amount-out-e-cards")}
                />
              </Grid.Col>
            </Grid.Row>

            <Form.Group label={props.t("cash-outs.cash-out-options")}>
              <Form.SwitchStack>
                <Form.Switch
                  label={props.t("cash-outs.close-pot")}
                  name="close_pot"
                  onChange={handleChange}
                  checked={values["close_pot"]}
                  type="checkbox"
                />
                <Form.Switch
                  label={props.t("cash-outs.collect-total")}
                  name="collect_total"
                  onChange={handleChange}
                  checked={values["collect_total"]}
                  type="checkbox"
                />
                <Form.Switch
                  label={props.t("cash-outs.isWithoutFee")}
                  name="isWithoutFee"
                  onChange={handleChange}
                  checked={values["isWithoutFee"]}
                  type="checkbox"
                />
              </Form.SwitchStack>
            </Form.Group>

            {!values["collect_total"] && (
              <Grid.Row>
                <Grid.Col lg={6} md={12}>
                  <Form.Group label={props.t("cash-outs.transfer-amount")}>
                    <AmountInput
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      name="transfer_amount"
                      placeholder={props.t("cash-outs.transfer-amount")}
                    />
                    <InputError name="transfer_amount" />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            )}

            <Form.Group label={props.t("cash-outs.select-rib")}>
              {ribs &&
                ribs.length &&
                !loadingRibs &&
                ribs.map((rib, index) => (
                  <Form.Radio
                    key={index}
                    label={`${rib.iban} (${rib.owner_name})`}
                    name="rib"
                    checked={values["rib"] == rib.reference}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={rib.reference}
                  />
                ))}
              {(!ribs || !ribs.length) && !loadingRibs && (
                <span>{props.t("cash-outs.no-ribs")}</span>
              )}
              {loadingRibs && <span>{props.t("cash-outs.loading-ribs")}</span>}
              <InputError name="rib" />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} color="warning" closeButton>
              {props.t("modals.close")}
            </Button>
            <Button type="submit" color="primary">
              {props.t("cash-outs.validate")}
            </Button>
          </Modal.Footer>
        </Form>

        <ConfirmModal
          confirmationText={props.t("cash-outs.create-confirm")}
          onConfirm={createCashOut(values)}
          show={confirmationModalShown}
          onHide={() => setConfirmationModalShown(false)}
        />
      </React.Fragment>
    );
  };

  const onSubmit = (values) => {
    setConfirmationModalShown(true);
  };

  const createCashOut = (values) => () => {
    return PotsService.createCashOut(props.pot.reference, values)
      .then((data) => {
        setConfirmationModalShown(false);
        props.onSuccess && props.onSuccess();
      })
      .catch((error) => {
        ErrorHandler.handleError(addToast, props.t, error);
        props.onHide && props.onHide();
      });
  };

  return (
    <Modal {...props} size="lg">
      <Modal.Header>
        <Modal.Title>
          {props.t("cash-outs.request-a-transfer-for-pot", {
            reference: props.pot.reference,
          })}
        </Modal.Title>
      </Modal.Header>

      <Formik
        onSubmit={onSubmit}
        render={render}
        initialValues={{
          collect_total: true,
          close_pot: false,
          transfer_amount: "",
          rib: "",
          category: props.pot.category,
        }}
        validationSchema={cashOutSchema.create_admin}
      />
    </Modal>
  );
}

export default withI18n()(CreateTransferModal);
