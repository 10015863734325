import React, {Fragment} from "react";
import {Card} from "tabler-react";
import {Button, Modal} from "react-bootstrap";
import {withI18n} from 'react-i18next';
import {useState} from "react";
import {commercialPartnerSchema} from '@unty-gl/validation';
import CommercialPartnersService from "../../../services/domain/CommercialPartnersService";
import {useToasts} from "react-toast-notifications";
import {Formik} from "formik";
import {Col, Container, FormGroup, Input, Label, Row} from 'reactstrap';
import DropZone from "../../common/dropzone/DropZone";
import LinksProvider from "../../../services/http/LinksProvider";
import {
  CommercialPartnerCategory,
  CommercialPartnerStatus,
  CommercialPartnerRegion,
  CommercialPartnerPriority
} from "../../../models/CommercialPartner";
import ErrorHandler from "../../../services/error/ErrorHandler";
import DraftEditor from "../../common/editor/DraftEditor";

const CommercialPartnerCreate = ({t}) => {

  const {addToast} = useToasts();

  const [loading, setLoading] = useState(false);

  const componentConfig = {
    iconFiletypes: [".jpg", ".png", ".jpeg"],
    showFiletypeIcon: true,
    postUrl: (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:1337') + LinksProvider.API.COMMERCIAL_PARTNERS.UPLOAD_PHOTO
  };

  const dzConfig = {
    eventHandlers: (setUrl, removeUrl) => {
      return {
        success: (file, response) => {
          if (response && response.data && response.data.image) {
            setUrl(response.data.image.url);
          }
        },
        removedfile: (file) => {
          removeUrl();
        }
      }
    },
    djsConfig: {
      maxFilesize: 10,
      maxFiles: 1,
      dictDefaultMessage: t('partners.upload-photo'),
      dictRemoveFile: t('partners.remove-photo'),
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/jpg,image/png",
      maxfilesexceeded: function (file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      sending: function (file, xhr, formData) {
        formData.append("image", file);
      },
      canceled: function (file) {
        console.log("delete photo")
      }
    }
  };

  const getPriorityFromValue = (val) => {
    return val !== undefined && val !== null && CommercialPartnerPriority.includes(val) ? parseInt(val) : 2;
  }

  const onSubmit = (values) => {
    console.log("submitted", values);
    setLoading(true);
    CommercialPartnersService.create(values)
      .then(commercial_partner => {
        addToast(t(`partners.added`), {
          appearance: 'success', autoDismiss: true
        });
        window.location = LinksProvider.formatUrl(LinksProvider.ROUTES.COMMERCIAL_PARTNERS);
      })
      .catch(error => {
        ErrorHandler.handleError(addToast, t, error)
      })
      .finally(() => setLoading(false));
  };


  return (<Fragment>
    <Formik
      validationSchema={commercialPartnerSchema.create}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: '',
        description: '',
        region: -1,
        category: 0,
        status: 0,
        priority: 2,
        url: '',
        temp_photo_url: ''
      }}
    >
      {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => {
        console.log("errors : ", errors);
        return (
          <form onSubmit={handleSubmit}>
            <Card.Header>
              <Modal.Title> {t('partners.create')}</Modal.Title>
            </Card.Header>
            <Card.Body>
              <Container>
                <Row>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label>{t('partners.name')}</Label>
                      <Input
                        id="name"
                        placeholder={t('partners.name')}
                        type="text"
                        value={values.name || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.name && errors.name ?
                        <p className="text-danger">{t('partners.validation.' + errors.name)}</p> : null}

                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label>{t('partners.description')}</Label>
                      <DraftEditor value={values['description']}
                                   editorName="description"
                                   editorId="description"
                      />
                    </FormGroup>
                    {touched.description && errors.description ? <p
                      className="text-danger">{t('partners.validation.'
                      + errors.description)}</p> : null}
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label>{t('partners.region')}</Label>
                      <Input
                        id="region"
                        placeholder={t('partners.region')}
                        type="select"
                        value={values.region ?? -1}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("region", parseInt(e.target.value));
                        }}
                        onBlur={handleBlur}
                      >
                        {Object.values(CommercialPartnerRegion).map(region =>
                          (<option value={region}>
                            {t('partners.region-' + region)}
                          </option>)
                        )}
                      </Input>
                      {touched.region && errors.region ?
                        <p className="text-danger">{t('publications.validation.' + errors.region)}</p> : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label>{t('partners.status')}</Label>
                      <Input
                        id="status"
                        placeholder={t('partners.status')}
                        type="select"
                        value={values.status || 0}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("status", parseInt(e.target.value))
                        }}
                        onBlur={handleBlur}
                      >
                        {Object.values(CommercialPartnerStatus).map(status =>
                          (<option value={status}>
                            {t('partners.status-' + status)}
                          </option>)
                        )}
                      </Input>
                      {touched.status && errors.status ?
                        <p className="text-danger">{t('partners.validation.' + errors.status)}</p> : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label>{t('partners.category')}</Label>
                      <Input
                        id="category"
                        placeholder={t('partners.category')}
                        type="select"
                        value={values.category || 0}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("category", parseInt(e.target.value))
                        }}
                        onBlur={handleBlur}
                      >
                        {Object.values(CommercialPartnerCategory).map(category =>
                          (<option value={category}>
                            {t('partners.category-' + category)}
                          </option>)
                        )}
                      </Input>
                      {touched.category && errors.category ?
                        <p className="text-danger">{t('partners.validation.' + errors.category)}</p> : null}
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label>{t('partners.priority')}</Label>
                      <Input
                        id="priority"
                        placeholder={t('partners.priority')}
                        type="select"
                        value={getPriorityFromValue(values.priority)}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("priority", parseInt(e.target.value))
                        }}
                        onBlur={handleBlur}
                      >
                        {CommercialPartnerPriority.map(priority =>
                          (<option value={priority}>
                            {t('partners.priority-' + priority)}
                          </option>)
                        )}
                      </Input>
                      {touched.priority && errors.priority ?
                        <p className="text-danger">{t('partners.validation.' + errors.priority)}</p> : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12">
                    <FormGroup>
                      <Label for="url">{t('partners.url')}</Label>
                      <Input
                        id="url"
                        type="text"
                        name="url"
                        value={values.url || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                    {touched.url && errors.url ?
                      <p className="text-danger">{t('partners.validation.' + errors.url)}</p> : null}
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" sm="6" className="mt-2">
                    <Row>
                      <Col xs="12" sm="12">
                        <DropZone
                          componentConfig={componentConfig}
                          dropZoneConfig={dzConfig.djsConfig}
                          eventHandlers={dzConfig.eventHandlers(
                            url => {
                              setFieldValue("temp_photo_url", url);
                            },
                            () => {
                              setFieldValue("temp_photo_url", null);
                            }
                          )}
                        />
                      </Col>
                    </Row>
                    <p className="w-100 mt-3 mb-1 text-center">{t('partners.photo')}</p>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
            <Card.Footer>
              <Button disabled={loading} type="submit" color="primary">{t('modals.save')}</Button>
            </Card.Footer>
          </form>
        )
      }}
    </Formik>
  </Fragment>);
};

export default withI18n()(CommercialPartnerCreate);
