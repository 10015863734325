// @flow
/**
 * ReportService
 * @module Services/domain/ReportService
 * @description Loads reporting data from the server
 */

import HttpService from "../http/HttpService";
import LinksProvider from "../http/LinksProvider";

const self = {};

self.TIME_FILTER_VALUES = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  DAY: 'DAY'
};

/**
 * Gets pot created report data
 * @author Seif Khiari
 * @alias createdPots
 * @memberof module:Services/http/ReportService
 * @param   {string} aggregation time
 * @param   {boolean} download download data as csv
 * @param   {int} origin App origin
 * @returns {Promise<any>}  A promise containing the report result
 */
self.createdPots = (aggregation: string, download: boolean, origin: int): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.REPORT.CREATED_POTS, {
      aggregation,
      download: download ? 1 : undefined,
      origin
    }));
};

/**
 * Gets activated pots report data
 * @author Seif Khiari
 * @alias activatedPots
 * @memberof module:Services/http/ReportService
 * @param   {string} aggregation time
 * @param   {boolean} download download data as csv
 * @param   {int} origin App origin
 * @returns {Promise<any>}  A promise containing the report result
 */
self.activatedPots = (aggregation: string, download: boolean, origin: int): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.REPORT.ACTIVATED_POTS, {
      aggregation,
      download: download ? 1 : undefined,
      origin
    }));

};

/**
 * Gets total collectd amount report data
 * @author Seif Khiari
 * @alias totalCollectedAmount
 * @memberof module:Services/http/ReportService
 * @param   {string} aggregation time
 * @param   {boolean} download download data as csv
 * @param   {int} origin App origin
 * @returns {Promise<any>}  A promise containing the report result
 */
self.totalCollectedAmount = (aggregation: string, download: boolean, origin: int): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.REPORT.TOTAL_COLLECTED_AMOUNT, {
      aggregation,
      download: download ? 1 : undefined,
      origin
    }));
};

/**
 * Gets Volume Transformed in CC
 * @author Hassen Charef
 * @alias VolumeTransformedInCC
 * @memberof module:Services/http/ReportService
 * @param   {string} aggregation time
 * @param   {boolean} download download data as csv
 * @param   {int} origin App origin
 * @returns {Promise<any>}  A promise containing the report result
 */
self.VolumeTransformedInCC = (aggregation: string, download: boolean, origin: int): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.REPORT.VOLUME_TRANSFORMED_IN_CC, {
      aggregation,
      download: download ? 1 : undefined,
      origin
    }));
};

/**
 * Gets total gift card generated amount
 * @author Seif Khiari
 * @alias totalGiftCardGeneratedAmount
 * @memberof module:Services/http/ReportService
 * @param   {string} aggregation time
 * @param   {boolean} download download data as csv
 * @param   {int} origin App origin
 * @returns {Promise<any>}  A promise containing the report result
 */
self.totalGiftCardGeneratedAmount = (aggregation: string, download: boolean, origin: int): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.REPORT.TOTAL_GIFT_CARD_GENERATED_AMOUNT, {
      aggregation,
      download: download ? 1 : undefined,
      origin
    }));
};

/**
 * Gets participants number report data
 * @author Seif Khiari
 * @alias participantsNumber
 * @memberof module:Services/http/ReportService
 * @param   {string} aggregation time
 * @param   {boolean} download download data as csv
 * @param   {int} origin App origin
 * @returns {Promise<any>}  A promise containing the report result
 */
self.participantsNumber = (aggregation: string, download: boolean, origin: int): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.REPORT.PARTICIPANTS_NUMBER, {
      aggregation,
      download: download ? 1 : undefined,
      origin
    }));
};

/**
 * Gets application working capital
 * @author Seif Khiari
 * @alias getWorkingCapital
 * @memberof module:Services/http/ReportService
 * @returns {Promise<any>}  A promise containing the report result
 */
self.getWorkingCapital = (): Promise<any> => {
  return HttpService
    .get(LinksProvider.formatFullUrl(LinksProvider.API.REPORT.CAPITAL))
    .then(data => data.account);
};

/**
 * Download report csv file
 * @author Seif Khiari
 * @alias downloadReport
 * @memberof module:Services/http/ReportService
 * @param   {string} url report service url
 * @returns {Promise<any>}  A promise containing the report result
 */
self.downloadReport = (url: string): Promise<any> => {
  return HttpService({
    url: url,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Keep-Original-Response': true
    }
  });
};

/**
 * Send the report as a csv file by email
 * @author Ghassen Manai
 * @alias sendReportByEmail
 * @memberof module:Services/http/ReportService
 * @param   {string} url report url
 * @returns {Promise<any>}  A promise containing the result of the request, which should be an null or an empty object or array
 */
self.sendReportByEmail = (url: string): Promise<any> => {
  return HttpService.get(url);
};

export default self;
