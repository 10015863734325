/* eslint-disable flowtype/require-return-type */
/* eslint-disable flowtype/require-parameter-type */
// @flow
import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {Button, Form, Grid} from "tabler-react";
import {Formik} from "formik";
import {withI18n} from "react-i18next";
import {clotureSchema} from "@unty-gl/validation";
import InputError from "../../common/input-error/InputError";
import UsersService from "../../../services/domain/UsersService";
import {useToasts} from "react-toast-notifications";


const CloseAccountModal = (props): React.Node => {
  const [loading, setLoading] = useState(false);
  const {addToast} = useToasts();

  const intitiativeOptions = [
    "",
    "Bank",
    "Client"
  ];

  const reasonOptions = ['','CommercialRelationshipEnd', 'BankingMobility', 'Retractation', 'Fraud', 'FCC_FICP_client', 'ClientNotCompliantToU', 'InactiveClient', 'DeceasedClient']

  const onSubmit = async (values, {resetForm, setErrors, setStatus, setSubmitting}) => {
    setLoading(true);

    try {
      const data = await UsersService.closeAccount(props.email, values);
      props.onHide && props.onHide();
      props.successHandler && props.successHandler(data.reference);
      addToast(props.t('pots.closed-request-success'), { appearance: 'success', autoDismiss: true });

    } catch (error) {
      if (error.data && error.data.errors) {
        if (error.data.errors[0].code === "E_ACCOUNT_ALREADY_CLOSED") {
          addToast(props.t(`Le compte est cloturé`), {
            appearance: 'error', autoDismiss: true
          });
        }
        if (error.data.errors[0].code === "E_ACCOUNT_IS_BEING_CLOSED") {
          addToast(props.t(`Le compte est deja en cloture`), {
            appearance: 'error', autoDismiss: true
          });
        }
      }
      if (error.data.code === "E_USER_NO_BANKING_ACCOUNT") {
        addToast(props.t(`Ce client n'a pas de référence Xpollens`), {
          appearance: 'error', autoDismiss: true
        });
      }

    } finally {
      setLoading(false);
    }

  };

  const render = ({handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors}) => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('pots.initiative')}>
                  <Form.Select

                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="initiative"
                    value={values.initiative}
                    placeholder={props.t('pots.initiative')}>
                      {intitiativeOptions.map((option)=>(
                        <option key={option} value={option}>{option === "" ? props.t(`pots.initiatives.empty-field`) : props.t(`pots.initiatives.${option}`)}</option>
                      ))}
                    </Form.Select>
                  <InputError name="initiative"/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label={props.t('pots.reason')}>
                  <Form.Select
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="reason"
                    placeholder={props.t('pots.reason')}>
                      {reasonOptions.map((option) => (
                        <option key={option} value={option}>{option === "" ? props.t(`pots.reasons.empty-field`) :props.t(`pots.reasons.${option}`)}</option>
                      ))}
                    </Form.Select>
                  <InputError name="reason"/>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} closeButton
                    color="warning" type="reset">{props.t('modals.close')}</Button>
            <Button type="submit" color="primary" disabled={loading || values.initiative == "" || values.reason == ""}
                    loading={loading}>{props.t('pots.close')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (
  <Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('pots.close-list')}</Modal.Title>
    </Modal.Header>
    <Formik
      onSubmit={onSubmit}
      render={render}
      validationSchema={clotureSchema}
      initialValues={{
        initiative: '',
        reason: '',
      }}
    />
  </Modal>);
};

export default withI18n()(CloseAccountModal);
