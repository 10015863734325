import * as React from 'react'
import {useState} from 'react'
import css from './UserInfo.scss';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {FormGroup, Label, Input} from 'reactstrap';
import Select from 'react-select';
import KycService from "../../../../services/domain/KycService";
import DateService from "../../../../services/utils/DateService";
import {AddressCountry} from "../../../../models/User";
import {Formik} from "formik";
import {withI18n} from 'react-i18next';
import UserRejectionMessageModal
  from "../../../modals/user-rejection-message-modal/UserRejectionMessageModal";
import {useToasts} from "react-toast-notifications";
import {userSchema} from '@unty-gl/validation';
import LinksProvider from '../../../../services/http/LinksProvider';
import ErrorHandler from "../../../../services/error/ErrorHandler";

const UserInfo = ({t, user, onUpdate, onKycProcessed}) => {
  const [showRejectionEmailModal, setShowRejectionEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {addToast} = useToasts();

  const addressCountryOptions = Object.entries(AddressCountry).map(([key, value]) => ({
    label: value,
    value: key
  }))

  const onSubmit = (values) => {
    setLoading(true);
    KycService.updateUser(user.email, values)
        .then((data) => {
          onUpdate(data);
          addToast(t(`kyc-user.update-success`), {
            appearance: 'success', autoDismiss: true
          });
        })
        .catch(error => {
            ErrorHandler.handleError(addToast, t, error);
        })
        .finally(() => setLoading(false));
  };

  return (
      <Formik
          enableReinitialize={true}
          validationSchema={userSchema.ADMIN_KYC_UPDATE_USER}
          onSubmit={onSubmit}
          initialValues={{
            first_name: user.first_name || '',
            last_name: user.last_name || '',
            birthdate: DateService.formatDateISO(user.birthdate),
            address_street: user.address_street || '',
            address_city: user.address_city || '',
            address_zip_code: user.address_zip_code || '',
            address_country: user.address_country || ''
          }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched
        } ) => {
          return (<Card className="card-profile">
                <UserRejectionMessageModal
                    user={user}
                    onConfirm={(user) => {
                      onUpdate(user);
                      onKycProcessed && onKycProcessed(user);
                      setShowRejectionEmailModal(false)
                    }}
                    show={showRejectionEmailModal}
                    onHide={() => setShowRejectionEmailModal(false)}
                />
                <Card.Body className="text-center">
                  <div className={css.user__content}>
                    <h4 className="m-0">{user.first_name} {user.last_name}</h4>
                    <a href={LinksProvider.formatUrl(LinksProvider.ROUTES.USER,
                        {id: user.email})}>{user.email}</a>
                    <p className="d-flex justify-content-center"><span
                        className="badge badge-primary">AppUserID : &nbsp;{user.external_reference}</span>
                    </p>

                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col sm={6} className="text-left">
                            <FormGroup>
                                <Label>{t('kyc-user.firstname')}</Label>
                                <Input
                                    name={`first_name`}
                                    id={`first_name_${user.email}`}
                                    placeholder={t('kyc-user.firstname')}
                                    value={values.first_name}
                                    onChange={handleChange}
                                />
                                {touched[`first_name_${user.email}`] && errors[`first_name_${user.email}`] ?
                                    <p className="text-danger">{t('partners.validation.' + errors[`first_name_${user.email}`])}</p> : null}
                            </FormGroup>
                        </Col>

                        <Col sm={6} className="text-left">
                            <FormGroup>
                                <Label>{t('kyc-user.lastname')}</Label>
                                <Input
                                    name={`last_name`}
                                    id={`last_name_${user.email}`}
                                    placeholder={t('kyc-user.lastname')}
                                    value={values.last_name}
                                    onChange={handleChange}
                                />
                                {touched[`last_name_${user.email}`] && errors[`last_name_${user.email}`] ?
                                    <p className="text-danger">{t('partners.validation.' + errors[`last_name_${user.email}`])}</p> : null}
                            </FormGroup>
                        </Col>

                        <Col sm={12} className="text-left">
                            <FormGroup>
                                <Label>{t('kyc-user.birthdate')}</Label>
                                <Input
                                    name={`birthdate`}
                                    id={`birthdate_${user.email}`}
                                    value={values.birthdate}
                                    onChange={handleChange}
                                />
                                {touched[`birthdate_${user.email}`] && errors[`birthdate_${user.email}`] ?
                                    <p className="text-danger">{t('partners.validation.' + errors[`birthdate_${user.email}`])}</p> : null}
                            </FormGroup>
                        </Col>

                        <Col sm={12} className="text-left">
                            <FormGroup>
                                <Label>{t('kyc-user.address-street')}</Label>
                                <Input
                                    name={`address_street`}
                                    id={`address_street_${user.email}`}
                                    placeholder={t('kyc-user.address-street')}
                                    value={values.address_street}
                                    onChange={handleChange}
                                />
                                {touched[`address_street_${user.email}`] && errors[`address_street_${user.email}`] ?
                                    <p className="text-danger">{t('partners.validation.' + errors[`address_street_${user.email}`])}</p> : null}
                            </FormGroup>
                        </Col>

                        <Col sm={6} className="text-left">
                            <FormGroup>
                                <Label>{t('kyc-user.address-city')}</Label>
                                <Input
                                    name={`address_city`}
                                    id={`address_city_${user.email}`}
                                    placeholder={t('kyc-user.address-city')}
                                    value={values.address_city}
                                    onChange={handleChange}
                                />
                                {touched[`address_city_${user.email}`] && errors[`address_city_${user.email}`] ?
                                    <p className="text-danger">{t('partners.validation.' + errors[`address_city_${user.email}`])}</p> : null}
                            </FormGroup>
                        </Col>

                        <Col sm={6} className="text-left">
                            <FormGroup>
                                <Label>{t('kyc-user.address-zipcode')}</Label>
                                <Input
                                    name={`address_zip_code`}
                                    id={`address_zip_code_${user.email}`}
                                    placeholder={t('kyc-user.address-zipcode')}
                                    value={values.address_zip_code}
                                    onChange={handleChange}
                                />
                                {touched[`address_zip_code_${user.email}`] && errors[`address_zip_code_${user.email}`] ?
                                    <p className="text-danger">{t('partners.validation.' + errors[`address_zip_code_${user.email}`])}</p> : null}
                            </FormGroup>
                        </Col>

                        <Col sm={6} className="text-left">
                        <FormGroup>
                            <Label>{t('kyc-user.address-country')}</Label>
                            <Select
                                name={`address_country`}
                                id={`address_country_${user.email}`}
                                defaultValue={addressCountryOptions.find(a => a.value === values.address_country)}
                                options={addressCountryOptions}
                                onChange={handleChange}
                            />
                            {touched[`address_country_${user.email}`] && errors[`address_country_${user.email}`] ?
                                <p className="text-danger">{t('partners.validation.' + errors[`address_country_${user.email}`])}</p> : null}
                        </FormGroup>
                      </Col>

                        <Col sm={6} className="m-auto">
                          <Button type="submit" color="success" disabled={loading}>
                            {t('kyc-user.user-save')}
                          </Button>&nbsp;
                        </Col>
                        <Col sm={12} className="mt-2">
                          <Button onClick={() => setShowRejectionEmailModal(true)} variant="danger">
                            {t('kyc-user.email-user-rejection-cause')}
                          </Button>&nbsp;
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
          )
        }}
      </Formik>);

};

export default withI18n()(UserInfo);
