import * as React from "react";
import {useState} from "react";
import {Modal} from "react-bootstrap";
import {Col, Container, FormGroup, Input, Label, Row} from 'reactstrap';
import {Button, Form} from "tabler-react";
import {withI18n} from 'react-i18next';
import {Formik} from "formik";
import {inspirationListSchema} from '@unty-gl/validation';
import InspirationListsService from "../../../services/domain/InspirationListsService";
import {
  InspirationListType, InspirationListStatus
} from "../../../models/InspirationList";
import InspirationListCreateAddProducts
  from "../../inspiration-lists/inspiration-list-create-add-products/InspirationListCreateAddProducts";

function CreateInspirationListModal(props): React.Node {
  const [listProducts, setListProducts] = useState([]);

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    values.products = listProducts;
    InspirationListsService.create(values)
      .then(list => {
        actions.setSubmitting(false);
        props.onHide();
        window.location.reload()
      })
      .catch(error => {
        props.onError(error);
        actions.setSubmitting(false);
      });
  };

  return (<Modal {...props} size="md">
    <Formik
      validationSchema={inspirationListSchema.create}
      onSubmit={onSubmit}
      initialValues={{
        type: InspirationListType.MARRIAGE,
        status: InspirationListStatus.INACTIVE,
        name: '',
        validity_end_date: null,
        validity_start_date: null
      }}
    >
      {({
          handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, isValid, errors, isSubmitting
        }) => {
        return <form
          onSubmit={handleSubmit}
        >
          <Modal.Header>
            <Modal.Title> {props.t('inspiration-lists.create')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs="12" sm="12">
                  <FormGroup>
                    <Label>{props.t('inspiration-lists.name')}</Label>
                    <Input
                      id="name"
                      placeholder={props.t('inspiration-lists.name')}
                      type="text"
                      value={values.name || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.name && errors.name ? <p
                      className="text-danger">{props.t('inspiration-lists.validation.'
                      + errors.name)}</p> : null}

                  </FormGroup>
                </Col>
                <Col xs="6" sm="6">
                  <FormGroup>
                    <Label className={"form-label"}>{props.t('inspiration-lists.status')}</Label>
                    <Input
                      type="select"
                      name="status"
                      id="status"
                      value={values.status}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("status", parseInt(e.target.value))
                      }}
                      onBlur={handleBlur}
                    >
                      {Object.values(InspirationListStatus).map(value =>
                        <option
                          value={value}>{props.t('inspiration-lists.status_' + value)}</option>
                      )}
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs="6" sm="6">
                  <FormGroup>
                    <Label className={"form-label"}>{props.t('inspiration-lists.type')}</Label>
                    <Input
                      type="select"
                      name="type"
                      id="type"
                      value={values.type}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("type", parseInt(e.target.value))
                      }}
                      onBlur={handleBlur}
                      disabled
                    >
                      {Object.values(InspirationListType).map(value =>
                        <option
                          value={value}>{props.t('inspiration-lists.type_' + value)}</option>
                      )}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6" sm="6">
                  <FormGroup>
                    <Label>{props.t('inspiration-lists.start-date')}</Label>
                    <Input
                      id="validity_start_date"
                      type="date"
                      value={values.validity_start_date || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                  {touched.validity_start_date && errors.validity_start_date ? <p
                    className="text-danger">{props.t('inspiration-lists.validation.'
                    + errors.validity_start_date)}</p> : null}
                </Col>
                <Col xs="6" sm="4">
                  <FormGroup>
                    <Label>{props.t('inspiration-lists.end-date')}</Label>
                    <Input
                      id="validity_end_date"
                      type="date"
                      value={values.validity_end_date || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                  {touched.validity_end_date && errors.validity_end_date ? <p
                    className="text-danger">{props.t('inspiration-lists.validation.'
                    + errors.validity_end_date)}</p> : null}
                </Col>
              </Row>
              <Row>
                <FormGroup>
                  <Label>{props.t('inspiration-lists.products')}</Label>
                  <InspirationListCreateAddProducts
                    listType={values.type}
                    onProductsChange={(products => setListProducts(products))}
                  />
                </FormGroup>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide} color="warning"
                    closeButton>{props.t('modals.cancel')}</Button>
            <Button disabled={isSubmitting} type="submit" color="primary">{props.t('modals.save')}</Button>
          </Modal.Footer>
        </form>
      }}
    </Formik>
  </Modal>);
}

export default withI18n()(CreateInspirationListModal);
