import React, {useEffect, useState} from "react";
import {Button, Card, Dimmer, Form, Grid, Loader,} from "tabler-react";
import {withI18n} from "react-i18next";
import PotsService from "../../../services/domain/PotsService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import LinksProvider from "../../../services/http/LinksProvider";
import Editable from "../../common/editable/Editable";
import DateService from "../../../services/utils/DateService";
import Amount from "../../common/amount/Amount";
import {PotCategory, PotPromoCodeStatus, PotVisibility} from "../../../models/Pot";
import InfoWidget from "../../common/info-widget/InfoWidget";
import {useToasts} from "react-toast-notifications";
import CurrencyService from "../../../services/utils/CurrencyService";
import SpouseInfo from "../../users/spouse-info/SpouseInfo";
import {homeStores} from '@unty-gl/validation';
import _ from 'lodash';
import StrongAuthService from "../../../services/utils/StrongAuthService";
import AuthService from '../../../services/domain/AuthService';
import CloseAccountModal from "../../modals/close-account-modal/CloseAccountModal";
import { XpollensAccountStatus } from "../../../models/Pot";
import FormatService from "../../../services/utils/FormatService"
import {XpollensAccountStatus as UserXpollensAccountStatus} from "../../../models/User";

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

const PotInfo = ({t, reference, onSetIsWeddingList ,userEmail}) => {

  const [model, setModel] = useState(null);
  const [isWeddingList, setIsWeddingList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPot, setLoadingPot] = useState(false);
  const [closeAccountModalShown , setCloseAccountModalShown] = useState(false);
  const {addToast} = useToasts();
  const HOME_STORES = homeStores.homeStores;
  const can_edit = AuthService.userAuthorizations.includes('AUTH_BO_POTS');

  useEffect(() => {
    onSetIsWeddingList(isWeddingList);
  }, [isWeddingList]);


  useEffect(() => {
    loadData();
  }, [reference,userEmail]);

  const loadData = async (newReference) => {
    setLoadingPot(true);
    try {
      const pot = await PotsService.get(newReference || reference);
      setModel(pot);
      setTagValue(getTagValue(pot))
    }
      finally{
        setLoadingPot(false)
      }
  };


  useEffect( () => {
    model && model.category === PotCategory.WEDDING_LIST
      ? setIsWeddingList(true)
      : setIsWeddingList(false);

  }, [model]);


  const updatePot = (request) => {
    setLoading(true);
    return PotsService.update(model.reference, request)
      .then(pot => {
        setModel({...model, ...pot});
        return pot;
      })
      .catch(err => {
        if( err.data.code == "E_CANNOT_UPDATE_POT_EVENT_DATE") {
          addToast(t(err.data.message),{
            appearance: 'error', autoDismiss: true
          })
        }
        else {ErrorHandler.handleError(addToast, t, err);}
        loadData();
      })
      .finally(() => setLoading(false));
  };

  const editPotInfo = (field) => {
    return (val) => {
      const request = {};
      if (field.indexOf('.') != -1) {
        const array = field.split('.');
        model[array[0]][array[1]] = val;
        request[array[0]] = {};
        request[array[0]][array[1]] = val;
      } else if (field
        == "hide_participations_amount"
        || field
        == "hide_participants_identity"
        || field
        == "receive_email_notifications") {
        request[field] = val == "true" ? true : false;
      } else if (field == "remove_promo_code") {
        request[field] = true;
      } else if (field === "event_date" || field === "end_of_contract_date") {
        request[field] = DateService.formatDateISO(val);
      } else if (field === "visibility") {
        model[field] = parseInt(val);
        request[field] = parseInt(val);
      } else {
        model[field] = val;
        request[field] = val;
      }
      setModel({...model});
      updatePot(request);
    };
  };


  const createTagOption = (label, value) => ({
    label: label || value.toProperCase(),
    value: value || label.toUpperCase(),
  });

  const defaultPotTags = [
    createTagOption('Test'),
    createTagOption('Doublon')
  ];

  const [tagValue, setTagValue]= useState([]);
  const [tagOptions, setTagOptions] = useState(defaultPotTags);


  const handleCreateTagOption = (inputTagValue) => {
      const newTagOption = createTagOption(inputTagValue);
      setTagOptions((prev) => [...prev, newTagOption]);
      setTagValue((prev) => (Array.isArray(prev) ? [...prev, newTagOption] : [newTagOption]));
  }

  const handleSubmitTag = () => {
    const tags = tagValue?.map(tag => tag.value) || [];
    updatePot({tags})
  }

  const getTagValue = (model) => {
    if (!model.tags) return null;

    if (typeof model.tags === "string") {
      model.tags = JSON.parse(model.tags);
    }

    return model.tags.map(t => createTagOption(null, t));
  }

return (<React.Fragment>
    {(model && !loadingPot && AuthService.userAuthorizations.includes('AUTH_BO_POTS')) && (<Grid.Row>
      <Grid.Col sm={6}
                lg={3}
      >
        <InfoWidget color="blue"
                    icon="users"
                    content={model.participants_count}
                    text={t('pots.participant-count')}
        />
      </Grid.Col>
      <Grid.Col sm={6}
                lg={3}
      >
        <InfoWidget color="green"
                    icon="dollar-sign"
                    content={StrongAuthService.showSecureSensitiveDataWithOTPForM1L() ?
                      <Amount amount={model.collected_amount}/> :
                      '-'}
                    text={t('pots.amount-collected')}
        />
      </Grid.Col>
      <Grid.Col sm={6}
                lg={3}
      >
        <InfoWidget color="yellow"
                    icon="dollar-sign"
                    content={StrongAuthService.showSecureSensitiveDataWithOTPForM1L() ?
                      <Amount amount={PotsService.getAmountLeft(model)}/> :
                      '-'}
                    text={t('pots.amount-left')}
        />
      </Grid.Col>
      <Grid.Col sm={6}
                lg={3}
      >
        <InfoWidget color="red"
                    icon="dollar-sign"
                    content={StrongAuthService.showSecureSensitiveDataWithOTPForM1L() ?
                      <Amount amount={model.spent_amount}/> :
                      '-'}
                    text={t('pots.amount-spent')}
        />
      </Grid.Col>
    </Grid.Row>)}
    <Grid.Row className={"text-left"}
              cards
              deck
    >
      <Grid.Col width={12}>
        <Card className="card-pot">
          <Card.Header>
            <Card.Title>
              {!loadingPot && !isWeddingList
                ? t('pots.pot-info')
                : t('lists.list-info')
              }
            </Card.Title>
            <Card.Options>
              {(model && !loadingPot) && (<a
                className={'btn btn-sm btn-primary text-white'}
                href={!isWeddingList
                  ? LinksProvider.formatCagnotteFrontFullUrl(LinksProvider.FRONT.POT.HOME_PAGE, {reference: model.reference})
                  : LinksProvider.formatListeFrontFullUrl(LinksProvider.FRONT.LIST.HOME_PAGE, {reference: model.reference})
                }
                target={'_blank'}
              >
                {!isWeddingList ? t('pots.go-to-pot') : t('lists.go-to-list')}
              </a>)}
            </Card.Options>
          </Card.Header>
          <Card.Body className="text-center">
            {loadingPot && (<Loader/>)}
            {(model && !loadingPot) && (<Dimmer active={loading}
                                                loader
            >
              <Grid.Row>
                <Grid.Col width={3}>
                  <Form.Group label={t('pots.reference')}
                              className={"text-left"}
                  >
                    {model.reference}
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={3}>
                  <Form.Group label={t('pots.name')}
                              className={"text-left"}
                  >
                    <Editable
                      initialValue={model.name}
                      undefinedText={t('globals.no-value')}
                      isValueClickable={true}
                      onSubmit={editPotInfo('name')}
                      type="textfield"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={3}>
                  <Form.Group label={t('pots.category')}
                              className={"text-left"}
                  >
                    {model.category === PotCategory.WEDDING_LIST ?
                      t(`pots.category_${PotCategory.WEDDING_LIST}`)
                      :
                      <Editable
                        initialValue={model.category}
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editPotInfo('category')}
                        type="select"
                        disabled={!can_edit}
                        options={Object.keys(_.omit(PotCategory, 'WEDDING_LIST')).map(key => {
                          return {
                            label: t(`pots.category_${PotCategory[key]}`),
                            value: PotCategory[key]
                          };
                        })}
                      />
                    }
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={3}>
                  <Form.Group label={t('pots.creation-date')}
                              className={"text-left"}
                  >
                    {DateService.formatTime(model.creation_date)}
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={3}>
                  <Form.Group label={t('pots.event-date')}
                              className={"text-left"}
                  >
                    <Editable
                      initialValue={model.event_date}
                      undefinedText={t('globals.no-value')}
                      isValueClickable={true}
                      onSubmit={editPotInfo('event_date')}
                      keepInitialValueIfFalsy={true}
                      type="date"
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={3}>
                  <Form.Group label={t('pots.end-of-contract')}
                              className={"text-left"}
                  >
                    <Editable
                      initialValue={model.end_of_contract_date}
                      isValueClickable={true}
                      type="date"
                      keepInitialValueIfFalsy={true}
                      onSubmit={editPotInfo('end_of_contract_date')}
                      disabled={!can_edit || model?.xpollens_account_status == XpollensAccountStatus.CLOSED}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label={t('pots.amount-max-bonus')}
                              className={"text-left"}
                  >
                    {can_edit ?
                      <Amount amount={model.amount_max_bonus === null ? 0 : model.amount_max_bonus}/> :
                      <span>{'-'}</span>
                    }
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={3}>
                  <Form.Group label={t('pots.amount-max-cashout')}
                              className={"text-left"}
                  >
                    {can_edit ?
                      <React.Fragment>
                        <Amount amount={(model.amount_max_cashout) ? model.amount_max_cashout : 0}/>
                        {model.cash_out_amount
                        > 0
                        && t('pots.amount-cashout-requested', {cash_out: CurrencyService.centsToEuro(model.cash_out_amount)})}
                      </React.Fragment> :
                      <span>{'-'}</span>
                    }
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={3}>
                  <Form.Group
                    label={!isWeddingList ? t('pots.pot-link') : t('lists.list-link')}
                    className={"text-left"}
                  >
                    <a href={!isWeddingList
                      ? LinksProvider.formatCagnotteFrontFullUrl(LinksProvider.FRONT.POT.HOME_PAGE, {reference: model.reference})
                      : LinksProvider.formatListeFrontFullUrl(LinksProvider.FRONT.LIST.HOME_PAGE, {reference: model.reference})
                    }
                       target={'_blank'}
                    >
                      {!isWeddingList
                        ? LinksProvider.formatCagnotteFrontFullUrl(LinksProvider.FRONT.POT.HOME_PAGE, {reference: model.reference})
                        : LinksProvider.formatListeFrontFullUrl(LinksProvider.FRONT.LIST.HOME_PAGE, {reference: model.reference})
                      }
                    </a>
                  </Form.Group>
                </Grid.Col>
                {isWeddingList &&
                <Grid.Col width={3}>
                  <Form.Group
                    label={t('pots.visibility')}
                    className={"text-left"}
                  >
                    <Editable
                      initialValue={model.visibility}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editPotInfo('visibility')}
                      type="select"
                      disabled={!can_edit}
                      options={Object.values(PotVisibility).map(value => {
                        return {
                          label: t(`pots.visibility_${value}`),
                          value: value
                        };
                      })}
                    />
                  </Form.Group>
                </Grid.Col>
                }
                {isWeddingList &&
                <Grid.Col width={3}>
                  <Form.Group label={t('users.home_store')}
                              className={"text-left"}
                  >
                    <Editable
                      initialValue={model.home_store}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editPotInfo('home_store')}
                      disabled={!can_edit}
                      options={HOME_STORES.map((val) => {
                        return {
                          value: val,
                          label: val
                        };
                      })}
                      type="select"
                    />
                  </Form.Group>
                </Grid.Col>
                }
                {isWeddingList && (
                  <Grid.Col width={3}>
                    <Form.Group label={t('pots.has-vdm-estimate')}
                                className={"text-left"}
                    >
                      {!!model.has_vdm_estimate ? t('pots.has-vdm-estimate-true') : t('pots.has-vdm-estimate-false')}
                    </Form.Group>
                  </Grid.Col>
                )}
                {can_edit && isWeddingList && model?.organizer?.xpollens_external_reference && (
                  <Grid.Col width={3}>
                    <Form.Group label={t('pots.close-list')}
                                className={"text-left"}
                    >
                    <Button color="danger" size="sm" onClick={() => setCloseAccountModalShown(true)} disabled={[UserXpollensAccountStatus.CLOSED, UserXpollensAccountStatus.PENDING_CLOSURE, UserXpollensAccountStatus.CLOSURE_REQUESTED, UserXpollensAccountStatus.BEING_CLOSED].includes(model?.organizer?.xpollens_account_status)}>
                      {t('pots.close')}
                      </Button>
                    </Form.Group>
                  </Grid.Col>
                )}
                <Grid.Col width={3}>
                    <Form.Group label={t('pots.flag.title')} className={"text-left"}>
                      <Editable
                        initialValue={getTagValue(model)}
                        type="creatableselect"
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        value={tagValue}
                        onChange={(newValue)=> {
                          setTagValue(newValue)
                        }}
                        onCreateOption={handleCreateTagOption}
                        options={tagOptions}
                        disabled={!can_edit}
                        isMulti={true}
                        onSubmit={handleSubmitTag}
                      />
                    </Form.Group>
                  </Grid.Col>
              </Grid.Row>
            </Dimmer>)}
          </Card.Body>
        </Card>
      </Grid.Col>

      <Grid.Col width={can_edit ? 6 : 12}>
        <Card>
          <Card.Header>
            <Card.Title>{t('pots.organizer-info')}</Card.Title>
          </Card.Header>
          <Card.Body>
            {loadingPot && (<Loader/>)}
            {(model && !loadingPot) && (<Dimmer active={loading}
                                                loader
            >
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label={t('pots.organizer-name')}
                              className={"text-left"}
                  >
                    <Editable
                      initialValue={model.organizer_name}
                      isValueClickable={true}
                      onSubmit={editPotInfo('organizer_name')}
                      disabled={!can_edit}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t('pots.organizer-email')}
                              className={"text-left"}
                  >
                    <a
                      href={LinksProvider.formatUrl(LinksProvider.ROUTES.USER, {id: model.organizer_email})}
                    >{model.organizer_email}</a>
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t('pots.active-notifications')}
                              className={"text-left"}
                  >
                    <Editable
                      initialValue={model.receive_email_notifications}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editPotInfo('receive_email_notifications')}
                      type="select"
                      disabled={!can_edit}
                      options={[{label: 'Oui', value: true}, {
                        label: 'Non', value: false
                      },]}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t('pots.hide-identity')}
                              className={"text-left"}
                  >
                    <Editable
                      initialValue={model.hide_participants_identity}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editPotInfo('hide_participants_identity')}
                      type="select"
                      disabled={!can_edit}
                      options={[{label: 'Oui', value: true}, {
                        label: 'Non', value: false
                      },]}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label={t('pots.hide-amount')}
                              className={"text-left"}
                  >
                    <Editable
                      initialValue={model.hide_participations_amount}
                      isValueClickable={true}
                      undefinedText={t('globals.no-value')}
                      onSubmit={editPotInfo('hide_participations_amount')}
                      type="select"
                      disabled={!can_edit}
                      options={[{label: 'Oui', value: true}, {
                        label: 'Non', value: false
                      },]}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Dimmer>)}
          </Card.Body>
        </Card>

        {isWeddingList &&
        <SpouseInfo userEmail={model && model.organizer_email}/>}

      </Grid.Col>


      {can_edit && <Grid.Col width={6}>
        <Grid.Row className={"text-left"}
                  cards
                  deck
        >
          <Grid.Col width={12}>
            <Card>
              <Card.Header>
                <Card.Title>{t('pots.promo-code')}</Card.Title>
                <Card.Options>
                  {can_edit && model && model.promo_code && (<Button color="danger"
                                                         size="sm"
                                                         onClick={editPotInfo('remove_promo_code')}
                  >{t('globals.remove')}</Button>)}
                </Card.Options>
              </Card.Header>
              <Card.Body>
                {loadingPot && (<Loader/>)}
                {(model && !loadingPot) && (<Dimmer active={loading}
                                                    loader
                >
                  <Grid.Row>
                    {!model.promo_code && (<Grid.Col width={6}>
                      <Form.Group label={t('pots.add-promo-code')}
                                  className={"text-left"}
                      >
                        <Editable
                          placeholder={t('pots.add-promo-code')}
                          isValueClickable={true}
                          undefinedText={t('globals.no-value')}
                          onSubmit={editPotInfo('promo_code')}
                          type="textfield"
                          disabled={!can_edit}
                        />
                      </Form.Group>
                    </Grid.Col>)}
                    {model.promo_code && (<React.Fragment>
                      <Grid.Col width={6}>
                        <Form.Group label={t('promo-codes.reference')}
                                    className={"text-left"}
                        >
                          <a
                            href={LinksProvider.formatUrl(LinksProvider.ROUTES.PROMO_CODE, {id: model.promo_code.reference})}
                          >{model.promo_code.reference}</a>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col width={6}>
                        <Form.Group label={t('promo-codes.amount')}
                                    className={"text-left"}
                        >
                          <Amount amount={model.promo_code.amount}/>
                        </Form.Group>
                      </Grid.Col>
                    </React.Fragment>)}
                  </Grid.Row>
                  {model.promo_code && (<React.Fragment>
                    <Grid.Row>
                      <React.Fragment>
                        <Grid.Col width={6}>
                          <Form.Group label={t('promo-codes.activation-date')}
                                      className={"text-left"}
                          >
                            {DateService.formatDate(model.promo_code.activation_date)}
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col width={6}>
                          <Form.Group label={t('promo-codes.expiration-date')}
                                      className={"text-left"}
                          >
                            {DateService.formatDate(model.promo_code.expiration_date)}
                          </Form.Group>
                        </Grid.Col>
                      </React.Fragment>
                    </Grid.Row>
                    <Grid.Row>
                      <React.Fragment>
                        <Grid.Col width={6}>
                          <Form.Group label={t('promo-codes.min-participant')}
                                      className={"text-left"}
                          >
                            {model.promo_code.min_participants_number}
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col width={6}>
                          <Form.Group label={t('promo-codes.min-amount')}
                                      className={"text-left"}
                          >
                            <Amount amount={model.promo_code.min_collected_amount}/>
                          </Form.Group>
                        </Grid.Col>
                      </React.Fragment>
                    </Grid.Row>
                    <Grid.Row>
                      <React.Fragment>
                        <Grid.Col width={6}>
                          <Form.Group label={t('promo-codes.status')}
                                      className={"text-left"}
                          >
                            {model.promo_code_status
                            === PotPromoCodeStatus.DEFINED
                            && (t('pots.promo-code-status-defined'))}
                            {model.promo_code_status
                            === PotPromoCodeStatus.VALIDATED
                            && (t('pots.promo-code-status-validated'))}
                            {model.promo_code_status
                            === PotPromoCodeStatus.USED
                            && (t('pots.promo-code-status-used'))}
                            {model.promo_code_status
                            === PotPromoCodeStatus.EXPIRED
                            && (t('pots.promo-code-status-expired'))}
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col width={6}>
                          <Form.Group label={t('promo-codes.date-threshold')}
                                      className={"text-left"}
                          >
                            {DateService.formatDate(model.promo_code.date_threshold)}
                          </Form.Group>
                        </Grid.Col>
                      </React.Fragment>
                    </Grid.Row>
                  </React.Fragment>)}
                </Dimmer>)}
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>}


      {(model && !loadingPot && model.beneficiary) && (<Grid.Col width={12}>
        <Card>
          <Card.Header>
            <Card.Title>{t('pots.beneficiary-info')}</Card.Title>
          </Card.Header>
          <Card.Body>
            <Dimmer active={loading}
                    loader
            >
              <Grid.Row>

                <Grid.Col width={3}>
                  <Form.Group label={t('pots.beneficiary-last-name')}
                              className={"text-left"}
                  >
                    <a
                      href={LinksProvider.formatUrl(LinksProvider.ROUTES.USER, {id: model.beneficiary.email})}
                    >{model.beneficiary.email}</a>
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={3}>
                  <Form.Group label={t('pots.beneficiary-first-name')}
                              className={"text-left"}
                  >
                    {model.beneficiary.first_name}
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={3}>
                  <Form.Group label={t('pots.beneficiary-last-name')}
                              className={"text-left"}
                  >
                    {model.beneficiary.last_name}
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={3}>
                  <Form.Group label={t('pots.giveaway-date')}
                              className={"text-left"}
                  >
                    {DateService.formatTime(model.giveaway_date)}
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Dimmer>
          </Card.Body>
        </Card>
      </Grid.Col>
      )}

      <CloseAccountModal
          show={closeAccountModalShown}
          dialogClassName="modal-lg"
          reference={model?.reference}
          email={model?.organizer_email}
          successHandler={loadData}
          onHide={()=>{
            setCloseAccountModalShown(false)
          }}
          onError={(error) => ErrorHandler.handleError(addToast,t,error)}
        />
    </Grid.Row>
  </React.Fragment>);
};

export default withI18n()(PotInfo);
