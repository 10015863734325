import * as React from "react";
import {useState} from "react";
import {Modal} from "react-bootstrap";
import {Col, Container, FormGroup, Input, Label, Row} from 'reactstrap';
import {Button, Form} from "tabler-react";
import {withI18n} from 'react-i18next';
import {Formik} from "formik";
import {customProductSchema} from "@unty-gl/validation";
import PotsService from "../../../services/domain/PotsService";
import {
  ProductAgeRange, ProductCategory, ProductGender, ProductStore
} from "../../../models/Product";
import DropZone from "../../common/dropzone/DropZone";
import LinksProvider from "../../../services/http/LinksProvider";
import AmountInput from "../../forms/amount-input/AmountInput";

function AddCustomProductModal(props): React.Node {
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [productPhotos, setProductPhotos] = useState([]);

  const componentConfig = {
    iconFiletypes: [".jpg", ".png", ".jpeg"],
    showFiletypeIcon: true,
    postUrl: process.env.REACT_APP_BACKEND_BASE_URL + LinksProvider.API.PRODUCTS.UPLOAD_PHOTO
  };

  const coverConfig = {
    eventHandlers: {
      success: (file, response) => {
        if (response && response.data && response.data.image) {
          setCoverPhoto(response.data.image.url);
        }
      }
    }, djsConfig: {
      maxFilesize: 10,
      maxFiles: 1,
      dictDefaultMessage: props.t('products.upload-cover-photo'),
      dictRemoveFile: props.t('products.remove-photo'),
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/jpg,image/png",
      maxfilesexceeded: function (file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      sending: function (file, xhr, formData) {
        formData.append("image", file);
      }
    }
  };

  const productPhotosConfig = {
    eventHandlers: {
      success: (file, response) => {
        const oldPhotos = productPhotos;
        if (response && response.data && response.data.image) {
          oldPhotos.push(response.data.image.url);
          setProductPhotos(oldPhotos);
        }
      }
    }, djsConfig: {
      maxFilesize: 10,
      maxFiles: 4,
      dictDefaultMessage: props.t('products.upload-product-photos'),
      dictRemoveFile: props.t('products.remove-photo'),
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/jpg,image/png",
      maxfilesexceeded: function (file) {
        this.removeFile(file);
      },
      sending: function (file, xhr, formData) {
        formData.append("image", file);
      }
    }
  };

  const onSubmit = (values, actions, reference) => {
    actions.setSubmitting(false);
    values.cover_photo = coverPhoto;
    values.photos = productPhotos;
    PotsService.createCustomWeddingListProduct(reference, values)
        .then(product => {
          actions.setSubmitting(true);
          props.onSuccess();
          props.onHide({message : props.t('pots.custom-product-added'), options : {appearance: 'success', autoDismiss: true}})
        })
        .catch(error => {
          if (error.data.code && error.data.code === "E_PRODUCT_MISSING_COVER_PHOTO") {
            actions.setErrors({temp_cover_photo_url: 'PHOTO_REQUIRED'});
            actions.isTouched = true;
          } else {
            props.onError(error);
          }
          actions.setSubmitting(true);
        });
  };

  return (<Modal {...props} size="md">
    <Formik
        validationSchema={customProductSchema.create}
        onSubmit={(values, actions) => onSubmit(values, actions, props.reference)}
        initialValues={{
          name: undefined,
          price: undefined
        }}
    >
      {({
        handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, isValid, errors,
      }) => {
        return <form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title> {props.t('products.add-custom')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs="12" sm="6">
                  <FormGroup>
                    <Label>Nom du produit</Label>
                    <Input
                        id="name"
                        placeholder="Nom du produit"
                        type="text"
                        value={values.name || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.name && errors.name ? <p
                        className="text-danger">{props.t('products.validation.'
                        + errors.name)}</p> : null}

                  </FormGroup>
                </Col>
                <Col xs="12" sm="6">
                  <FormGroup>
                    <Label>Prix en €</Label>
                    <AmountInput
                        id="price"
                        placeholder="Prix"
                        name="price"
                        value={values.price || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}/>
                    {touched.price && errors.price ? <p
                        className="text-danger">{props.t('products.validation.'
                        + errors.price)}</p> : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12">
                  <FormGroup>
                    <Label for="descriptionText">Description</Label>
                    <Input
                        id="descriptionText"
                        type="textarea"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                  </FormGroup>
                  {touched.description && errors.description ? <p
                      className="text-danger">{props.t('products.validation.'
                      + errors.description)}</p> : null}
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="4">
                  <DropZone
                      componentConfig={componentConfig}
                      dropZoneConfig={coverConfig.djsConfig}
                      eventHandlers={coverConfig.eventHandlers}
                  />
                  {errors.temp_cover_photo_url ? <p
                      className="text-danger text-center">{props.t('products.validation.'
                      + errors.temp_cover_photo_url)}</p> : null}
                </Col>
                <Col xs="12" sm="8">
                  <DropZone
                      componentConfig={componentConfig}
                      dropZoneConfig={productPhotosConfig.djsConfig}
                      eventHandlers={productPhotosConfig.eventHandlers}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide} color="warning"
                    closeButton>{props.t('modals.cancel')}</Button>
            <Button onClick={(e) => console.log("formik errors", errors)} type="submit"
                    color="primary">{props.t('modals.save')}</Button>
          </Modal.Footer>
        </form>
      }}
    </Formik>
  </Modal>);
}

export default withI18n()(AddCustomProductModal);
