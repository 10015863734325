// @flow
import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {Button, Form, Grid} from "tabler-react";
import {Formik} from "formik";
import {withI18n} from "react-i18next";
import {ribSchema} from '@unty-gl/validation';
import InputError from "../../common/input-error/InputError";
import UsersService from "../../../services/domain/UsersService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";
import {TARGET_APP} from "../../../models/RequestInformation";


const CreateRibModal = (props): React.Node => {

  const [loading, setLoading] = useState(false);
  const {addToast} = useToasts();

  const onSubmit = (values, {resetForm, setErrors, setStatus, setSubmitting}) => {
    setLoading(true);
    UsersService.addRib(props.email, values, props.targetApp ? props.targetApp : TARGET_APP.CNT)
      .then(data => {
        props.onHide && props.onHide();
        props.successHandler && props.successHandler();
      })
      .catch(error => {
        ErrorHandler.handleError(addToast, props.t, error);
        if (error.data && error.data.data) {
          if (error.data.data.code === "E_BANKING_556") {
            setErrors({bic: "INVALID"});
            addToast(props.t(`Erreur BIC incorrect`), {
              appearance: 'error', autoDismiss: true
            });
          }
        }
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const render = ({handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors}) => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('ribs.owner_name')}>
                  <Form.Input
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="owner_name"
                    placeholder={props.t('ribs.owner_name')}/>
                  <InputError name="owner_name"/>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('ribs.iban')}>
                  <Form.Input

                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="iban"
                    placeholder={props.t('ribs.iban')}/>
                  <InputError name="iban"/>
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label={props.t('ribs.bic')}>
                  <Form.Input
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="bic"
                    placeholder={props.t('ribs.bic')}/>
                  <InputError name="bic"/>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} closeButton
                    color="warning" type="reset">{props.t('modals.close')}</Button>
            <Button type="submit" color="primary" disabled={loading}
                    loading={loading}>{props.t('promo-codes.create')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('ribs.add_rib')}</Modal.Title>
    </Modal.Header>
    <Formik
      onSubmit={onSubmit}
      render={render}
      validationSchema={ribSchema.create}
      initialValues={{
        bic: '',
        iban: '',
        owner: ''
      }}
    />
  </Modal>);
};

export default withI18n()(CreateRibModal);
