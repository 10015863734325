// @flow
import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {Button, Form, Grid} from "tabler-react";
import {FormGroup, Label, Input} from 'reactstrap';
import {Formik} from "formik";
import {withI18n} from "react-i18next";
import {promoCodeSchema} from '@unty-gl/validation';
import InputError from "../../common/input-error/InputError";
import PromoCodesService from "../../../services/domain/PromoCodesService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {useToasts} from "react-toast-notifications";


const PromoCodeAddAsBannerModal = (props): React.Node => {

  const [loading, setLoading] = useState(false);
  const {addToast} = useToasts();

  const onSubmit = (values, actions) => {
    setLoading(true);
    PromoCodesService.addAsBanner(props.promoCodeReference, values)
      .then(promoCode => {
        props.onHide && props.onHide();
        props.afterUpdate && props.afterUpdate();
      })
      .catch(error => {
        const err = {data: {errors: null}};
        err.data.errors = [error.data];
        ErrorHandler.handleError(addToast, props.t, err)
      })
      .finally(() => setLoading(false));
  };

  const render = ({handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors}) => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Grid.Row>
              <Grid.Col>
                <FormGroup>
                  <Label className={"form-label"} for="poolTypeSelect">{props.t('promo-codes.pool-type')}</Label>
                  <Input
                    type="select"
                    name="pool_type"
                    id="poolTypeSelect"
                    value={props.promoCodePoolType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  >
                    <option
                      value={props.promoCodePoolType}>{props.t('promo-codes.pool_type_' + props.promoCodePoolType)}</option>
                  </Input>
                </FormGroup>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label={props.t('promo-codes.banner-text')}>
                  <Form.Input
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="banner_text"
                    placeholder={props.t('promo-codes.banner-text')}/>
                  <InputError name="banner_text"/>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} closeButton
                    color="warning">{props.t('modals.close')}</Button>
            <Button type="submit" color="primary" disabled={loading}
                    loading={loading}>{props.t('promo-codes.mark')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('promo-codes.mark-as-banner')}</Modal.Title>
    </Modal.Header>
    <Formik
      onSubmit={onSubmit}
      render={render}
      validationSchema={promoCodeSchema.mark_as_banner}
      initialValues={{
        pool_type: props.promoCodePoolType,
        banner_text: '',
      }}
    />
  </Modal>);
};

export default withI18n()(PromoCodeAddAsBannerModal);
